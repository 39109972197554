import { QueryTerminalsArgs, Terminal } from "@/graphql/types";
import { gql } from "@apollo/client";
import { useQuery, useResult } from "@vue/apollo-composable";
import { activeActivity } from "@/plugins/i18n";
import { watch } from "vue";

export type TerminalsData = {
  terminals: Terminal[];
};

export const TERMINAL_FIELDS = `
    id
    name
    description
    users{id}
    categories{categoryId checked partialChecked}
    printerSettings
    printerMargins
    margeActive
    active
    codeBar
    printBarcode
    printStockPos
    allowDiscountLines
    number
    activityId
    lastUse
    userId
`;
export const TERMINALS = gql`
    query Terminals($input: TerminalsInput!){
        terminals(input: $input) {
            ${TERMINAL_FIELDS}
        }
    }
`;

export const useTerminals = () => {
  const {
    loading: terminalsLoading,
    result,
    refetch: refreshTerminals,
  } = useQuery<TerminalsData, QueryTerminalsArgs>(TERMINALS, {
    input: {
      activityId: activeActivity.value.id,
    },
  });
  const terminals = useResult<TerminalsData, Terminal[], Terminal[]>(
    result,
    [],
    (res) => {
      return res.terminals;
    }
  );

  watch(
    () => activeActivity.value.id,
    (activityId) => {
      void refreshTerminals({ input: { activityId } });
    }
  );

  return { terminalsLoading, terminals };
};
