
import { defineComponent, ref, watch } from "vue";
import { activeActivity, subscriptionExpired } from "@/plugins/i18n";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "ExpirationDialog",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const visible = ref<boolean>(false);

    watch([route, activeActivity.value], ([newRoute]) => {
      const expired = subscriptionExpired();
      if (expired && newRoute.name !== "activity") {
        visible.value = true;
      } else visible.value = false;
    });
    const onRenewClick = () => {
      visible.value = false;
      router.push("/workspace/activity");
    };
    return {
      visible,
      onRenewClick,
    };
  },
});
